import { pathOr } from 'ramda';

import {
    IAddTimeSlotApiPayload,
    IAddTimeSlotByPlanningRequestIdPayload,
    IAddTimeSlotPayload,
    IAutoPlanEmployeeApiSuccessResponse,
    IAutoPlanEmployeeFailedResponse,
    IAutoPlanEmployeePayload,
    IAutoPlanEmployeeSuccessResponse,
    IMoveUpdateTimeslotPayload,
    IRemoveTimeSlotPayload,
    TAddTimeSlotPayload,
    TAutoPlanEmployeeApiResponse,
    TAutoPlanEmployeeResponse,
    TUpdateTimeSlotPayload,
} from '../../../models/interventions/timeslots';
import {
    IExaminationReason,
    IFetchMedicalCenterTimeslotsByEmployeePayload,
    IMedicalCenterFreeSlotsPayload,
} from '../../../models/interventions/medicalExaminations';
import { getMedExamToAddId } from '../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import ROUTE_KEYS from '../../../routeKeys';
import { createEpic } from '../../index';
import { getLocale } from '../../i18n/selectors';
import { getQueryParams, getRouteKey, getRoutePayload } from '../../location/selectors';
import { isAllSeatsSelected } from '../../company/selected/selectors';
import {
    ADD_TIMESLOT,
    AUTO_PLAN_EMPLOYEES,
    FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    REMOVE_TIMESLOT,
    UPDATE_TIMESLOT,
} from '../types';
import {
    addTimeslotActions,
    autoPlanEmployeesFailed,
    autoPlanEmployeesSucceeded,
    fetchCompanyMedicalCentersFreeSlotsFailed,
    fetchCompanyMedicalCentersFreeSlotsSucceeded,
    fetchCompanyMedicalCenterTimeslotsByEmployeeActions,
    fetchPlannedMedicalExaminationsActions,
    removeTimeslotFailed,
    removeTimeslotSucceeded,
    updateTimeSlotActions,
    fetchReservedMedicalExamination,
} from '../actions';
import { getExaminationReasons } from '../selectors';
import { getSelectedCompanyInfoSeatCompanyCode } from '../../company/info/selectors';

// updateTimeslotEpic
createEpic<TUpdateTimeSlotPayload>({
    onActionType: UPDATE_TIMESLOT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const locale = getLocale(state);
            const { payload } = action;

            // TODO: add flow for IReplaceEmployeeUpdateTimeslotPayload
            // Not included in FPP track 1 as this functionality is already been
            // disabled for more than 2 years at this point
            const moveTimeSlotPayload = payload as IMoveUpdateTimeslotPayload;

            await api.interventions.timeslots.updateTimeSlot({
                ...moveTimeSlotPayload,
                locale,
            });

            if (getRouteKey(state) === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED) {
                const filter = getQueryParams(state);
                dispatch(fetchPlannedMedicalExaminationsActions.trigger(filter));
            }

            dispatch(updateTimeSlotActions.succeeded({ timeSlotId: moveTimeSlotPayload.newTimeSlot.id }));

            done();
        } catch (error) {
            dispatch(updateTimeSlotActions.failed(error));
            done();
        }
    },
    latest: false,
});

// removeTimeslotEpic
createEpic<IRemoveTimeSlotPayload>({
    onActionType: REMOVE_TIMESLOT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const { payload } = action;
            const locale = getLocale(state);

            await api.interventions.timeslots.removeTimeSlot({
                ...payload,
                locale,
            });

            if (getRouteKey(state) === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED) {
                const filter = getQueryParams(state);
                dispatch(fetchPlannedMedicalExaminationsActions.trigger(filter));
            }

            // Fetch bufferzones detail (contains planned & reserved timeslots data)
            if (getRouteKey(state) === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL) {
                const routePayload = getRoutePayload(state);
                const reservedMedicalExaminationId = pathOr(null, ['reservedMedicalExaminationId'], routePayload);

                if (reservedMedicalExaminationId) {
                    dispatch(fetchReservedMedicalExamination({
                        reservedMedicalExaminationId,
                        forceRefresh: true,
                    }));
                }

            }

            dispatch(removeTimeslotSucceeded());

            done();
        } catch (error) {
            dispatch(removeTimeslotFailed(error));
            done();
        }
    },
    latest: false,
});

// fetchCompanyMedicalCentersFreeSlotsEpic
createEpic<IMedicalCenterFreeSlotsPayload>({
    onActionType: FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    latest: false, // because each fetch has it's own async status
    async processReturn({ api, action }) {
        const payload = action.payload;

        try {
            const response = await api.interventions.timeslots.fetchCompanyMedicalCentersFreeSlots(payload);

            return fetchCompanyMedicalCentersFreeSlotsSucceeded({
                medicalCenters: response,
                requestId: payload.requestId,
            });

        } catch (error) {
            return fetchCompanyMedicalCentersFreeSlotsFailed({
                ...error,
                requestId: payload.requestId,
            });
        }
    },
});

// autoPlanEmployeesEpic
createEpic<IAutoPlanEmployeePayload[]>({
    onActionType: AUTO_PLAN_EMPLOYEES,
    async processReturn({ api, action, getState }) {
        try {
            const examinationReasons = getExaminationReasons(getState());
            const response = await api.interventions.timeslots.autoPlanEmployees(action.payload);

            const fullResponse = addPlanParametersToResponse(response, examinationReasons, action.payload);

            return autoPlanEmployeesSucceeded(fullResponse);
        } catch (error) {
            return autoPlanEmployeesFailed(error);
        }
    },
    latest: false,
});

const addPlanParametersToResponse = (
    autoPlanEmployeeResponse: TAutoPlanEmployeeApiResponse[],
    examinationReasons: IExaminationReason[],
    payload: IAutoPlanEmployeePayload[],
): TAutoPlanEmployeeResponse[] => {
    const autoPlanResult = autoPlanEmployeeResponse.map((autoPlanResponse) => {
        const isFailedAutoPlanAttempt = pathOr<string>('', ['errorMessage'], autoPlanResponse).length > 0;

        const examinationToPlan = payload.find((examToPlan) => (
            getMedExamToAddId(examToPlan) === getMedExamToAddId(autoPlanResponse)
        ));

        if (isFailedAutoPlanAttempt) {
            if (!examinationToPlan) {
                return autoPlanResponse as IAutoPlanEmployeeFailedResponse;
            }

            return {
                ...autoPlanResponse,
                startDate: examinationToPlan.startDate,
                startTime: examinationToPlan.startTime,
                endTime: examinationToPlan.endTime,
            } as IAutoPlanEmployeeFailedResponse;
        }

        const autoPlanEmployeeSuccess = autoPlanResponse as IAutoPlanEmployeeApiSuccessResponse;

        const { start, end, timeSlotId, activityId, examinationReason, ...rest } = autoPlanEmployeeSuccess;

        const examinationReasonFull = examinationReasons.find((reason) => {
            return reason.code === examinationReason.code || reason.id === examinationReason.id;
        });

        if (examinationToPlan) {
            return {
                ...rest,
                ...!!examinationReasonFull && {
                    examinationReason: examinationReasonFull,
                },
                timeSlot: {
                    start,
                    end,
                    id: timeSlotId,
                    activityId,
                },
                startDate: examinationToPlan.startDate,
                startTime: examinationToPlan.startTime,
                endTime: examinationToPlan.endTime,
            } as IAutoPlanEmployeeSuccessResponse;
        }

        return {
            ...autoPlanResponse,
            timeSlot: {
                start,
                end,
                id: timeSlotId,
                activityId,
            },
        } as IAutoPlanEmployeeSuccessResponse;
    });

    return autoPlanResult;
};

// fetchCompanyMedicalCenterTimeslotsEpic
createEpic<IFetchMedicalCenterTimeslotsByEmployeePayload>({
    onActionType: FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const payload = action.payload;

            const seatCode = state.company_selected.selectedCompanySeat.company.companyCode;
            const allSeatsSelected = isAllSeatsSelected(state);
            const locale = getLocale(state);

            const {
                showFullFamily = allSeatsSelected,
                ...rest
            } = payload;

            const timeCells = await api.interventions.timeslots.fetchCompanyMedicalCenterTimeSlotsByEmployee({
                ...rest,
                seatCode,
                locale,
                showFullFamily,
            });

            return fetchCompanyMedicalCenterTimeslotsByEmployeeActions.succeeded(timeCells);
        } catch (error) {
            return fetchCompanyMedicalCenterTimeslotsByEmployeeActions.failed(error);
        }
    },
    latest: false,
});

// addTimeslotEpic
createEpic<TAddTimeSlotPayload>({
    onActionType: ADD_TIMESLOT,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();

            const { payload } = action;
            const locale = getLocale(state);

            if (isAddTimeslotByPlanningRequestIdPayload(payload)) {
                await api.interventions.timeslots.addTimeslotByPlanningRecordId({
                    ...payload,
                    locale,
                });
                return addTimeslotActions.succeeded({ timeSlotId: payload.timeSlotId });
            }

            await api.interventions.timeslots.addTimeslot({
                ...payload,
                locale,
            } as IAddTimeSlotApiPayload);

            return addTimeslotActions.succeeded({ timeSlotId: payload.timeSlotId });
        } catch (error) {
            return addTimeslotActions.failed(error);
        }
    },
    latest: false,
});

function isAddTimeslotByPlanningRequestIdPayload(
    payload: IAddTimeSlotPayload | IAddTimeSlotByPlanningRequestIdPayload,
): payload is IAddTimeSlotByPlanningRequestIdPayload {
    const payloadWithPlanningRecord = payload as IAddTimeSlotByPlanningRequestIdPayload;
    return (
        payloadWithPlanningRecord &&
        typeof payloadWithPlanningRecord.planningRequestMSId === 'number'
    );
}
