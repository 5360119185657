import { pathOr } from 'ramda';

import { HEADER_NAME } from '../../../../config/api.config';
import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import { get, IResponseType } from '../../../../utils/api/requestWrapper';
import { ICompanyCodeWithShowFullFamilyPayload, IStartEndDatePayload } from '../../../../models/admin/company';
import { IDocument } from '../../../../models/general/documents';
import {
    IFilterValues,
} from '../../../../views/interventions/MedicalExaminations/Planned/index/PlannedMedicalExaminations.type';
import { ILocationTypeCodes } from '../../../../models/general/location';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../../config/planning.config';
import { IPlannedMedicalExamination } from '../../../../models/interventions/medicalExaminations/planned';
import { IPlannedMedicalExaminationsFilter } from '../../../../models/interventions/medicalExaminations';
import { Locales } from '../../../../config/i18n.config';
import { ONE_SECOND, ONE_MINUTE } from '../../../../utils/core/time/periodsInMillis';
import { monthOffsetFromNow, now } from '../../../../utils/core/date/getSpecificDate';
import { getFilenameFromBffHeaders } from '../../../general/getFilenameFromHeaders';
import { COMPANY_MEDICAL_EXAMINATIONS_URL } from '../medicalExaminations.const';

export const DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS: Partial<IFilterValues> = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(monthOffsetFromNow(6)),
};

export function fetchPlanned(
    companyCode: string,
    {
        startDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
        medicalCenterCode = null,
    }: IPlannedMedicalExaminationsFilter,
    showFullFamily: boolean = true,
    locale: Locales,
) {
    return get<IPlannedMedicalExamination[]>({
        ...bffDefaultApiConfig({ locale }),
        url: COMPANY_MEDICAL_EXAMINATIONS_URL.PLANNED,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            start: startDate,
            end: endDate,
            ...!!medicalCenterCode && {
                locationCode: medicalCenterCode,
            },
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response: IPlannedMedicalExamination[] | null) => {
            // The BFF returns status 204 when no medical examinations were found.
            // When this happens, the response is null. To prevent the call from timing out,
            // we return an empty array
            if (!response) {
                return [];
            }

            return response.map((pme): IPlannedMedicalExamination => {
                const { remarks = null, ...rest } = pme;

                // Manually set location code to 'In Company medical center code' ('7')
                // as a fallback when no location code is found & location type
                // code is customer.
                const locationCode = pathOr(
                    pme.location.typeCode === ILocationTypeCodes.CUSTOMER
                        ? IN_COMPANY_MEDICAL_CENTER_CODE
                        : null,
                    ['location', 'code'],
                    pme,
                );

                return {
                    ...rest,
                    ...!!(typeof remarks === 'string') && {
                        remarks,
                    },
                    location: {
                        ...pme.location,
                        code: locationCode,
                    },
                };
            });
        },
        timeoutInMillis: 90 * ONE_SECOND,
    });
}

export function exportPlannedExaminations(
    {
        companyCode,
        showFullFamily,
        startDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    }: ICompanyCodeWithShowFullFamilyPayload & IStartEndDatePayload,
    defaultFilename: string,
    locale: Locales,
) {
    return get<IDocument>({
        ...bffDefaultApiConfig({
            locale,
            headers: {
                [HEADER_NAME.ACCEPT]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        }),
        url: COMPANY_MEDICAL_EXAMINATIONS_URL.PLANNED,
        responseType: IResponseType.blob,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            start: startDate,
            end: endDate,
        },
        timeoutInMillis: ONE_MINUTE,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromBffHeaders(headers, '.xlsx', defaultFilename),
            };
        },
    });
}
