import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { get, post } from '../../../../utils/api/requestWrapper';
import {
    IMedicalCenterFreeSlotsPayload,
    IFetchMedicalCenterTimeslotsByEmployeeApiPayload,
    IMedicalExaminationTimeSlot,
} from '../../../../models/interventions/medicalExaminations';
import { ONE_SECOND } from '../../../../utils/core/time/periodsInMillis';
import { URL, BFF_URL } from '../timeSlots.const';

export const fetchCompanyMedicalCentersFreeSlots = (requestBody: IMedicalCenterFreeSlotsPayload) =>
    post({
        url: URL.COMPANY_MEDICAL_CENTERS_FREESLOTS,
        body: requestBody.medicalCenterData,
        mapResponse: (response) => response.timeCells,
    });

export const fetchCompanyMedicalCenterTimeSlotsByEmployee = ({
    locale,
    seatCode,
    employeeId,
    medicalCenterCode,
    employmentId,
    examinationReasonCode,
    planningRequestId,
    showFullFamily,
    startDate,
    endDate,
}: IFetchMedicalCenterTimeslotsByEmployeeApiPayload) => {
    return get<IMedicalExaminationTimeSlot[]>({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.TIMESLOTS_BY_EMPLOYEE,
        pathParams: {
            organizationalUnitCode: seatCode,
            employeeId,
        },
        queryParams: {
            medicalCenterCode,
            employmentId,
            showFullFamily: showFullFamily.toString(),
            // Optionals
            ...!!examinationReasonCode && {
                examinationReasonCode,
            },
            ...!!planningRequestId && {
                planningRequestId,
            },
            ...!!startDate && {
                start: startDate,
            },
            ...!!endDate && {
                end: endDate,
            },
        },
        mapResponse: (response: IMedicalExaminationTimeSlot[] | null) => {
            if (!response) {
                return [];
            }

            return response;
        },
        timeoutInMillis: 90 * ONE_SECOND,
    });
};
